import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import { ElasticLang, getElasticIndexName } from 'features/Layouts/Layout/Vendors/Elastic/elasticHelpers';
import { useElasticSearch } from 'hooks/useElasticSearch';
import { useRouter } from 'next/router';
import { TeaserModuleFeature } from '../TeaserModule';

export const NewsTeaserFeature: React.FC<Content.NewsTeaser> = ({ content, settings }) => {
	const { heading, link, imagePosition, columns, quantity, tags, types, rootFolder } = content?.properties ?? {};
	const { hideImages, noTopMargin, hideImagesOnMobile, mediaAspectRatio } = settings?.properties ?? {};

	const siteContext = useSiteContext();
	const dictionary = useDictionaryContext();
	const router = useRouter();

	const { theme } = siteContext ?? {};

	const lang = router.locale.split('-')[0] as ElasticLang;

	const { results } = useElasticSearch({
		index: getElasticIndexName(theme, lang),
		parentId: rootFolder.id,
		tags: tags?.map((tag) => tag.name),
		types: types?.map((type) => type.name),
		sort: 'date',
		query: '*',
		limit: quantity || columns,
		lang: lang,
	});

	return (
		<TeaserModuleFeature
			content={{
				properties: {
					heading,
					link,
					imagePosition,
					columns,
					teaserItems: results
						? results.map((item) => {
								const { date, heading, image, link, teaser } = item ?? {};
								const teaserItem: Content.TeaserItem = {
									content: {
										properties: {
											heading,
											date,
											urlWrapperLink: link.url,
											additionalInfo: teaser,
											picture: hideImages
												? null
												: image
												? [
														{
															content: {
																properties: {
																	image: { url: image.url },
																	altText: image.altText,
																},
															},
														},
												  ]
												: null,
										},
									},
								};
								return teaserItem;
						  })
						: null,
					noItemsMessage: dictionary.getServiceInfo('NoNews'),
				},
			}}
			settings={{ properties: { noTopMargin, hideImagesOnMobile, mediaAspectRatio } }}
		/>
	);
};
