import { formatDate } from 'designsystem/utilities/date';
import { elasticSearchFetcher } from 'helpers/swrAxiosFetcher';
import { ElasticSearchResponseItem } from 'pages/api/indexSearch';
import useSWR from 'swr';

export const elasticSearchTagsName = 'tags';
export const elasticSearchTypesName = 'types';
export interface UseElasticSearchProps {
	index: string;
	parentId: number;
	query?: string;
	sort?: 'date' | 'id' | 'title' | 'default'; // default is accomplished by not passing a sorting value
	category?: 'documents';
	tags?: string[];
	types?: string[];
	dates?: {
		from?: string;
		to?: string;
	};
	operatorForTagsAndTypes?: 'and' | 'or';
	limit?: number;
	lang?: string;
}

export const transformData = (data, category = null): Models.CatalogListItem[] | Models.DocumentCatalogListItem[] => {
	return data?.map((item) => {
		const { contentImage, date, description, expirationDate, tags, title, types, url, articleId } = item ?? {};
		if (category === 'documents') {
			const listItem: Models.DocumentCatalogListItem = {
				heading: title || null,
				teaser: articleId ? description || null : null, // No need when catalog is in catalog
				number: articleId || null,
				link: {
					url: url || null,
					children: title || null,
				},
				date: date ? formatDate(String(date), 'yyyy-mm-dd') : null,
			};
			return listItem;
		}
		const listItem: Models.CatalogListItem = {
			heading: title || null,
			link: {
				url: url || null,
				name: title || null,
			},
			teaser: description || null,
			date: date ? String(date) : null,
			types: types || null,
			image: contentImage ? { url: contentImage, altText: '' } : null,
			expirationDate: expirationDate && new Date(expirationDate).getTime() > 0 ? formatDate(String(expirationDate), 'yyyy-mm-dd') : null,
			tags: Array.isArray(tags)
				? tags.map((tag) => {
						return { name: tag };
				  })
				: null,
		};
		return listItem;
	});
};

export const useElasticSearch = ({
	index,
	parentId,
	query = '*',
	sort = 'date',
	category,
	tags,
	types,
	dates,
	operatorForTagsAndTypes = 'and',
	limit,
	lang,
}: UseElasticSearchProps) => {
	const { data, error, isLoading, isValidating } = useSWR(
		{
			index: index,
			parentId: parentId,
			query: query,
			tags: tags && tags.length ? tags : null,
			types: types && types.length ? types : null,
			from: dates?.from,
			to: dates?.to,
			operatorForTagsAndTypes: operatorForTagsAndTypes,
			sort: sort === 'default' ? '' : sort,
			limit: limit,
			lang: lang,
		},
		elasticSearchFetcher,
	);
	const results = transformData(data as ElasticSearchResponseItem[], category);
	return {
		results: results,
		isLoading,
		isValidating,
		isError: error,
	};
};
