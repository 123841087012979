export type ElasticLang = 'da' | 'en' | 'de';

export const getElasticIndexName = (theme: Content.Theme, lang?: ElasticLang): string => {
	switch (theme) {
		case 'SKAT':
			if (lang === 'da') return 'skat-da';
			if (lang === 'en') return 'skat-en';
			if (lang === 'de') return 'skat-de';
			return 'skat'; // umbrella engine for multiple languages
		case 'TOLDST':
			if (lang === 'da') return 'toldst-da';
			if (lang === 'en') return 'toldst-en';
			if (lang === 'de') return 'toldst-de';
			return 'toldst';
		case 'MOTORST':
			if (lang === 'da') return 'motorst-da';
			if (lang === 'en') return 'motorst-en';
			if (lang === 'de') return 'motorst-de';
			return 'motorst';
		case 'VURDST':
			if (lang === 'da') return 'vurdst-da';
			if (lang === 'en') return 'vurdst-en';
			if (lang === 'de') return 'vurdst-de';
			return 'vurdst';
		case 'GAELDST':
			if (lang === 'da') return 'gaeldst-da';
			if (lang === 'en') return 'gaeldst-en';
			if (lang === 'de') return 'gaeldst-de';
			return 'gaeldst';
		case 'SANST':
			return 'sanst-da';
		case 'SKM':
			return 'skm-da';
		case 'SKTST':
			return 'sktst-da';
		case 'SKTFV':
			return 'sktfv-da';
		case 'ADST':
			return 'adst-da';
		case 'UFST':
			return 'ufst-da';
		case 'ITTI':
			return 'itti-da';
		case 'WEBGUIDE':
			return 'webguide-da';
	}
};
